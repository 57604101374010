import React, {FunctionComponent, useState} from "react"
import {UpdateType} from "../../../update/update.type"
import {findLatestUpdate, showMissingUpdatesNotice} from "./updates.util"
import CompanyMissingUpdatesNotice from "./notice.component"
import CompanyUpdate from "./update/update.component"
import styles from "./updates.module.sass"

type CompanyUpdatesProps = {
    updates: UpdateType[]
}

const CompanyUpdatesTile: FunctionComponent<CompanyUpdatesProps> = ({ updates }) => {
    const [openUpdateId, setOpenUpdateId] = useState<string | undefined>(findLatestUpdate(updates)?.id)

    if (updates.length < 1) return <></>
    return (
        <div className={styles.updates}>
            <h4>Company Updates</h4>
            {updates.length > 0 && (
                <div className={styles.list}>
                    {updates.map(update => (
                        <CompanyUpdate
                            opened={update.id === openUpdateId}
                            setOpenUpdateId={setOpenUpdateId}
                            update={update}
                            key={update.id}
                        />
                    ))}
                </div>
            )}
            {showMissingUpdatesNotice(updates) && <CompanyMissingUpdatesNotice/>}
        </div>
    )
}

export default CompanyUpdatesTile