import {FunctionComponent, useState} from "react"
import {useQueryClient} from "react-query"
import {useNavigate} from "react-router-dom"
import {QUERY_KEY__INVESTOR_ONBOARDING} from "../../../../investor/onboarding/onboarding.query"
import Button from "../../../../../tech/button/button.component"
import {ButtonSize} from "../../../../../tech/button/size.enum"
import {ButtonState} from "../../../../../tech/button/state.enum"
import {ButtonStyle} from "../../../../../tech/button/style.enum"
import {DEAL_INVEST, ONBOARDING} from "../../../../../paths"
import {InvestorOnboardingType} from "../../../../investor/onboarding/onboarding.type"
import Alert from "../../../../../tech/alert/alert.component"
import {AlertType} from "../../../../../tech/alert/type.enum"
import {useApp} from "../../../../../tech/app/context/app.context"
import LoadingDots from "../../../../../tech/loading/dots/dots.component"
import styles from "./blurred.module.sass"

export type DealCommitmentPosition = "OVERVIEW" | "SINGLE"

type DealCommitmentBlurredProps = {
    dealId: string
    position: DealCommitmentPosition
}

const DealCommitmentBlurred: FunctionComponent<DealCommitmentBlurredProps> = ({
    dealId,
    position
}) => {
    const app = useApp()
    const queryClient = useQueryClient()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    async function click() {
        setLoading(true)
        await app.fetchClient.dealApi.assignToCurrentInvestor([dealId])
        setLoading(false)
        if (onboarding?.blocked) {
            navigate(ONBOARDING)
        }
        else {
            navigate(DEAL_INVEST(dealId))
        }
    }

    const onboarding = queryClient.getQueryData<InvestorOnboardingType>(QUERY_KEY__INVESTOR_ONBOARDING())

    return (
        <div className={`${styles.blurred} ${position === "OVERVIEW" ? styles.overview : ""}`}>
            {position === "OVERVIEW" && (
                <div className={styles.imageOverview}>
                    <img
                        src="/blurred/deal-overview-blurred.jpg"
                        alt="Complete KYC to see details"
                    />
                </div>
            )}
            {position === "SINGLE" && (
                <div className={styles.imageSingle}>
                    <img
                        src="/blurred/single-deal-commitment-fees.jpg"
                        alt="Complete KYC to see details"
                    />
                </div>
            )}
            {onboarding && (
                <div className={styles.button}>
                    {{
                        "ACCREDITED_INVESTOR_STATUS_TO_BE_DECLARED": (
                            <FinalizeOnboardingButton
                                click={click}
                                loading={loading}
                                position={position}
                            />
                        ),
                        "GENERAL_DATA": (
                            <FinalizeOnboardingButton
                                click={click}
                                loading={loading}
                                position={position}
                            />
                        ),
                        "KYC_DOCUMENT_UPLOAD_NECESSARY": (
                            <FinalizeOnboardingButton
                                click={click}
                                loading={loading}
                                position={position}
                            />
                        ),
                        "KYC_WAITING_FOR_REVIEW": (
                            <Alert
                                type={AlertType.WARNING}
                                text={
                                    <>
                                        You'll see more details after your <strong>KYC documents</strong> have been successfully <strong>reviewed</strong>.
                                    </>
                                }
                            />
                        ),
                        "ACKNOWLEDGE_SUCCESS": (<></>),
                        "NONE": (<></>),
                        "COMPLETE_KYC": (
                            <>
                                {loading ? (
                                    <LoadingDots/>
                                ) : (
                                    <Button
                                        label="Complete KYC to see details"
                                        type="button"
                                        size={ButtonSize.SMALL}
                                        state={ButtonState.ACTIVE}
                                        style={position === "OVERVIEW" ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY}
                                        onClick={async (e) => {
                                            e.stopPropagation()
                                            await click()
                                        }}
                                        fullWidth
                                    />
                                )}
                            </>
                        ),
                    }[onboarding.stage.type]}
                </div>
            )}
        </div>
    )
}

export default DealCommitmentBlurred

const FinalizeOnboardingButton: FunctionComponent<{
    click: () => Promise<void>
    loading: boolean
    position: DealCommitmentPosition,
}> = ({
    click,
    loading,
    position
}) => {
    if (loading) {
        return <LoadingDots/>
    }
    return (
        <Button
            label="Complete onboarding to see details"
            type="button"
            size={ButtonSize.SMALL}
            state={ButtonState.ACTIVE}
            style={position === "OVERVIEW" ? ButtonStyle.SECONDARY : ButtonStyle.PRIMARY}
            onClick={async (e) => {
                e.stopPropagation()
                await click()
            }}
            fullWidth
        />
    )
}