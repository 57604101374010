const DEALS_TO_ASSIGN_IMPLICITLY = "DEALS_TO_ASSIGN_IMPLICITLY"

export function addDealToAssignImplicitly(dealId: string) {
    const storedArray = getDealsToAssignImplicitly()
    if (!storedArray.includes(dealId)) {
        storedArray.push(dealId)
        window.localStorage.setItem(DEALS_TO_ASSIGN_IMPLICITLY, JSON.stringify(storedArray))
    }
}

export function getDealsToAssignImplicitly(): string[] {
    const storedArrayJson = window.localStorage.getItem(DEALS_TO_ASSIGN_IMPLICITLY) || "[]"
    return JSON.parse(storedArrayJson)
}

export function resetDealsToAssignImplicitly() {
    window.localStorage.removeItem(DEALS_TO_ASSIGN_IMPLICITLY)
}