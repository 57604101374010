import React, {FunctionComponent} from "react"
import DealCommitmentNote from "./note/note.component"
import DealCommitmentStatus from "./status/status.component"
import DealCommitmentBlurred, {DealCommitmentPosition} from "./blurred/blurred.component"
import {DealInterface} from "../../deal.interface"
import DealCommitmentAmount from "./amount/amount.component"
import styles from "./commitment.module.sass"
import {InvestmentType} from "../../../investment/investment.type"
import DealCardInvestmentNote from "../investment-note/investment-note.component"

type DealCommitmentProps = {
    deal: DealInterface
    investment?: InvestmentType
    blurredPosition: DealCommitmentPosition
}

const DealCommitment: FunctionComponent<DealCommitmentProps> = ({
    deal,
    investment,
    blurredPosition
}) => {
    if (deal.currency !== undefined && deal.investedAmount !== undefined && deal.minTarget !== undefined && deal.maxTarget !== undefined && deal.outcome !== undefined) {
        return (
            <div className={styles.commitment}>
                <div className={styles.status}>
                    <DealCommitmentStatus
                        dealCurrency={deal.currency}
                        investedAmount={deal.investedAmount}
                        minTarget={deal.minTarget}
                    />
                </div>
                <div className={styles.amount}>
                    <DealCommitmentAmount
                        dealCurrency={deal.currency}
                        investedAmount={deal.investedAmount}
                        minTarget={deal.minTarget}
                        maxTarget={deal.maxTarget}
                    />
                </div>
                <div className={styles.note}>
                    <DealCommitmentNote
                        dealCurrency={deal.currency}
                        investedAmount={deal.investedAmount}
                        minTarget={deal.minTarget}
                        maxTarget={deal.maxTarget}
                        outcome={deal.outcome}
                        hasInvestment={investment !== undefined}
                    />
                    {investment && (
                        <DealCardInvestmentNote investment={investment}/>
                    )}
                </div>
            </div>
        )
    }
    return (
        <DealCommitmentBlurred
            dealId={deal.id!}
            position={blurredPosition}
        />
    )
}

export default DealCommitment