import React, {FunctionComponent, useState} from "react"
import {FaqEntryType} from "./faq-entry.type"
import MinusCircleIcon from "../../../../tech/icon/minus-circle.component"
import PlusCircleIcon from "../../../../tech/icon/plus-circle.component"
import Markdown from "../../../../tech/markdown/markdown.component"
import styles from "./faq-entry.module.sass"

type HelpFaqEntryProps = {
    faqEntry: FaqEntryType
}

const HelpFaqEntry: FunctionComponent<HelpFaqEntryProps> = ({ faqEntry }) => {
    const [opened, setOpened] = useState<boolean>(false)
    return (
        <div className={`${styles.entry} ${opened ? styles.opened : ""}`}>
            <div
                 className={styles.head}
                 onClick={() => setOpened(!opened)}
            >
                <div>
                    <div className={styles.bullet}/>
                </div>
                <div>
                    <div>{faqEntry.title}</div>
                </div>
                <div className={styles.toggle}>
                    <div>
                        {opened ? (
                            <MinusCircleIcon
                                width={20}
                                height={20}
                                fillClass="fill-icon-theme"
                            />
                        ) : (
                            <PlusCircleIcon
                                width={20}
                                height={20}
                                fillClass="fill-icon-theme"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.inner}>
                    <div>
                        <Markdown markdown={faqEntry.text}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HelpFaqEntry