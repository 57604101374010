import React, {useEffect, useState} from "react"
import {confirmSignUp, resendSignUpCode} from "aws-amplify/auth"
import LoadingDots from "../../loading/dots/dots.component"
import Alert from "../../alert/alert.component"
import {AlertType} from "../../alert/type.enum"
import {sendJavaScriptError} from "../../logging/error-logger"
import AuthHead from "../head/head.component"
import {useNavigate, useSearchParams} from "react-router-dom"
import {addQueryParameters} from "../../routing/parameters.util"
import {SIGN_IN} from "../../../paths"
import {useApp} from "../../app/context/app.context"
import Button from "../../button/button.component"
import {ButtonSize} from "../../button/size.enum"
import {ButtonState} from "../../button/state.enum"
import {ButtonStyle} from "../../button/style.enum"
import styles from "./verify-email.module.sass"

const VerifyEmail = () => {
    const app = useApp()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [state, setState] = useState<"LOADING" | "ERROR_VERIFICATION" | "ERROR_RESENT" | "LINK_EXPIRED" | "NEW_LINK_SENT">("LOADING")
    const code = searchParams.get("c") || undefined
    const email = searchParams.get("email") || undefined
    const username = searchParams.get("username") || undefined

    useEffect(() => {
        const verify = async () => {
            try {
                await confirmSignUp({
                    username: username!,
                    confirmationCode: code!
                })
                navigate(buildRedirectUrl(email))
            }
            catch (err) {
                // @ts-ignore
                if (err["message"] !== undefined && err["message"] === "User cannot be confirmed. Current status is CONFIRMED") {
                    navigate(buildRedirectUrl(email))
                }
                else if (err && err.toString().includes("ExpiredCodeException")) {
                    setState("LINK_EXPIRED")
                }
                else {
                    console.error(err)
                    await sendJavaScriptError(err, app.fetchClient)
                    setState("ERROR_VERIFICATION")
                }
            }
        }

        if (code === undefined || username === undefined) {
            setState("ERROR_VERIFICATION")
        }
        else {
            verify()
        }
    }, [code, email, username, app.fetchClient, navigate])

    async function requestNewVerificationLink() {
        setState("LOADING")
        try {
            await resendSignUpCode({
                username: username!
            })
            setState("NEW_LINK_SENT")
        }
        catch (error) {
            await app.fetchClient.loggingApi.sendError(
                new Date(),
                error as string,
                window.location.pathname
            )
            setState("ERROR_RESENT")
        }
    }

    return (
        <>
            <AuthHead title="Verify Email"/>
            {state === "LOADING" && <LoadingDots/>}
            {state === "LINK_EXPIRED" && (
                <>
                    <div className={styles.alert}>
                        <Alert
                            type={AlertType.WARNING}
                            text="The verification link you have used has expired."
                        />
                    </div>
                    <Button
                        label="Request a new verfication link"
                        type="button"
                        size={ButtonSize.MEDIUM}
                        state={ButtonState.ACTIVE}
                        style={ButtonStyle.PRIMARY}
                        onClick={requestNewVerificationLink}
                    />
                </>
            )}
            {state === "NEW_LINK_SENT" && (
                <Alert
                    type={AlertType.SUCCESS}
                    text="We have sent you a new verification link."
                />
            )}
            {(state === "ERROR_VERIFICATION" || state === "ERROR_RESENT") && (
                <Alert
                    type={AlertType.ERROR}
                    text={state === "ERROR_VERIFICATION" ? "Failed to verify." : "Failed to send a new verification link."}
                />
            )}
        </>
    )
}

export default VerifyEmail

function buildRedirectUrl(email?: string): string {
    const parameters = [{ key: "confirmed", value: "true" }]
    if (email) {
        parameters.push({
            key: "email",
            value: email.trim().replaceAll(" ", "+")
        })
    }
    return addQueryParameters(
        SIGN_IN,
        parameters
    )
}