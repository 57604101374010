import React, {FunctionComponent} from "react"
import ReactMarkdown from "react-markdown"

type MarkdownProps = {
    markdown: string
}

const Markdown: FunctionComponent<MarkdownProps> = ({ markdown }) => {
    return (
        <ReactMarkdown
            components={{
                a: ({node, ...props}) => (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                        {props.children}
                    </a>
                )
            }}
        >
            {markdown}
        </ReactMarkdown>
    )
}

export default Markdown